import gql from "graphql-tag";

const getOriginalOrderPreview = gql`
  query getOriginalOrderPreview(
    $initDate: Date
    $endDate: Date
    $enterpriseID: ID!
    $trackCode: String
    $limit: Int!
    $offset: Int!
  ) {
    OriginalOrderPreview: getOriginalOrderPreview(
      initDate: $initDate
      endDate: $endDate
      enterpriseID: $enterpriseID
      trackCode: $trackCode
      limit: $limit
      offset: $offset
    ) {
      validatedCount
      validatedOrders {
        id
        csvHistorialID
        blocked
        validated
        orderInfo {
          trackCode
          paymentMethodID
          paymentProofID
          serviceID
          Service {
            name
          }
          packageEnvelope
          packageWeight
          packageSizeID
          PackageSize {
            name
          }
          packageQuantity
          productDescription
          productPrice
          reverseLogistic
          crossdocking
          pickUpBranchID
          pickUpAddress
          pickUpPoint {
            type
            coordinates
          }
          pickUpAddressReference
          pickUpPrimaryReference
          pickUpSecondaryReference
          pickUpNotes
          pickUpContactName
          pickUpContactPhone
          pickUpContactDocumentTypeID
          pickUpContactDocumentNumber
          pickUpContactEmail
          pickUpPostalCode
          dropBranchID
          dropAddress
          dropPoint {
            type
            coordinates
          }
          dropAddressReference
          dropPrimaryReference
          dropSecondaryReference
          dropNotes
          dropContactName
          dropContactPhone
          dropContactDocumentTypeID
          dropContactDocumentNumber
          dropContactEmail
          dropPostalCode
        }
        errors {
          field
          input
          messages
          label
        }
      }
      unvalidatedCount
      unvalidatedOrders {
        id
        csvHistorialID
        blocked
        validated
        orderInfo {
          trackCode
          paymentMethodID
          paymentProofID
          serviceID
          packageEnvelope
          packageWeight
          packageSizeID
          PackageSize {
            name
          }
          Service {
            name
          }
          packageQuantity
          productDescription
          productPrice
          reverseLogistic
          crossdocking
          pickUpBranchID
          pickUpAddress
          pickUpPoint {
            type
            coordinates
          }
          pickUpAddressReference
          pickUpPrimaryReference
          pickUpSecondaryReference
          pickUpNotes
          pickUpContactName
          pickUpContactPhone
          pickUpContactDocumentTypeID
          pickUpContactDocumentNumber
          pickUpContactEmail
          pickUpPostalCode
          dropBranchID
          dropAddress
          dropPoint {
            type
            coordinates
          }
          dropAddressReference
          dropPrimaryReference
          dropSecondaryReference
          dropNotes
          dropContactName
          dropContactPhone
          dropContactDocumentTypeID
          dropContactDocumentNumber
          dropContactEmail
          dropPostalCode
        }
        errors {
          field
          input
          messages
          label
        }
      }
    }
  }
`;

const getCountUnvalidateOrder = gql`
  query getCountUnvalidateOrder(  
    $enterpriseID: ID!
    $trackCode: String
  ) {
    UnvalidateOrder: getCountUnvalidateOrder(
      enterpriseID: $enterpriseID
      trackCode: $trackCode
    ) {
      unvalidatedCount
    }
  }
`;

const getOriginalOrderByID = gql`
  query getOriginalOrderByID
  ($id: ID!,
  $notify:Boolean) {
    GetOriginalOrderByID: getOriginalOrderByID
    (id: $id,
      notify: $notify) {
      id
      csvHistorialID
      blocked
      validated
      orderInfo {
        trackCode
        paymentMethodID
        paymentProofID
        serviceID
        packageEnvelope
        packageWeight
        packageSizeID
        Service {
          name
        }
        PackageSize {
          name
        }
        packageQuantity
        productDescription
        productPrice
        reverseLogistic
        crossdocking
        pickUpBranchID
        pickUpAddress
        pickUpPoint {
          type
          coordinates
        }
        pickUpAddressReference
        pickUpPrimaryReference
        pickUpSecondaryReference
        pickUpNotes
        pickUpContactName
        pickUpContactPhone
        pickUpContactDocumentTypeID
        pickUpContactDocumentNumber
        pickUpContactEmail
        pickUpPostalCode
        dropBranchID
        dropAddress
        dropPoint {
          type
          coordinates
        }
        dropAddressReference
        dropPrimaryReference
        dropSecondaryReference
        dropNotes
        dropContactName
        dropContactPhone
        dropContactDocumentTypeID
        dropContactDocumentNumber
        dropContactEmail
        dropPostalCode
      }
      errors {
        field
        input
        messages
        label
      }
    }
  }
`;
export { getOriginalOrderPreview,getCountUnvalidateOrder, getOriginalOrderByID };
