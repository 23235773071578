import { Icon, Avatar, Dropdown, Menu, Button, Modal, Tag } from "antd"; // Layout
import React from "react";
import styled from "styled-components";
import { compose } from "recompose";
import io from "socket.io-client";
import { withGlobalContext } from "../../utils/globalContext";
import { AuthService } from "../../services/AuthService.ts";
import ConfirmSessionClosure from "../Modals/ConfirmSessionClosure";
import { createSuperAdminRecord } from "../../graphQl/mutations/myCompany";
import {
  getOriginalOrderPreview
} from "../../graphQl/queries/previewCsv";
import NoticeIcon from "../NoticeIcon";
import { Link } from "@reach/router";
import * as router from "../../routes/routes";

const socket = io("wss://cm-legacy.chazki.com:8444");
const CustomHeader = styled.div`
  background: white;
  height: 6.5rem;
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
  z-index: 50;
  position: fixed;
  width: 100%;
  padding: 0 4rem;
`;

const CustomContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  ${props =>
    props.showSideBar
      ? `right: 220px;
      position: relative;
      @media only screen and (max-width: 900px) {
        right: 110px !important;
      }
      `
      : `position: relative;
        right: 50px;`}
`;

const Chronometer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  ${props => (props.startCountdown ? `color: red` : `color: #595959`)}
`;
let time;

const authService = new AuthService();

class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: "00:00:00",
      startCountdown: false,
      showCounter: false,
      showModal: false,
      anyActiveTab: true,
      zeldaOut: false,
      deliveriesWrongSize:0,
      activateBell:false,
      bell:false
    };
    this.Ref = React.createRef();
  }

  getDataAcountWrongOrders = async() =>{
    const {
      data: { OriginalOrderPreview },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getOriginalOrderPreview,
      variables: {
        enterpriseID: parseInt(this.props.userEnterpriseData.enterpriseID, 10),
        limit:12,
        offset:0
      },
    });
    this.setState({
      deliveriesWrongSize: OriginalOrderPreview.unvalidatedCount,
      bell:OriginalOrderPreview.unvalidatedCount===0 ? false:true
    });
  }

  componentDidMount =  () => {
    this.getDataAcountWrongOrders();
    this.resetTime();
    this.clearTimer(this.getDeadTime());
    window.onload = this.resetTime;
    document.onclick = this.resetTime;
    localStorage.setItem(
      "anyActiveTab",
      JSON.stringify(this.state.anyActiveTab)
    );
    localStorage.setItem("zeldaOut", JSON.stringify(this.state.zeldaOut));
    if (!socket.connect) {
      socket.connect();
    } else {
      console.log("socket conected!");
    }
    socket.on("JobStatus", enterprise => {    
      if(enterprise.enterpriseID===this.props.userEnterpriseData.enterpriseID) { 
        this.getDataAcountWrongOrders();
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.anyActiveTab !== this.state.anyActiveTab &&
      !this.state.zeldaOut
    ) {
      localStorage.setItem(
        "anyActiveTab",
        JSON.stringify(this.state.anyActiveTab)
      );
      if (this.state.anyActiveTab) {
        this.resetTime();
      }
    }
  }

  saveSuperAdminMod = async () => {
    if (
      this.props.userEnterpriseData.Enterprise.extra.superAdmin &&
      this.props.userEnterpriseData.Enterprise.extra.superAdmin.active === true
    ) {
      const variables = {
        operationType: "SALIDA",
        operatorUserID: this.props.userEnterpriseData.Enterprise.extra
          .superAdmin.operatorUserID,
        clientUserID: this.props.authUser.profile.sub,
        enterpriseID: parseInt(this.props.userEnterpriseData.enterpriseID, 10),
      };

      await this.props.client
        .mutate({
          mutation: createSuperAdminRecord,
          update: (cache, { data: { CreateSuperAdminRecord } }) => {
            console.log("response", CreateSuperAdminRecord);
          },
          variables,
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  logout = async () => {
    await this.saveSuperAdminMod();
    localStorage.removeItem("isFirstLogin");
    localStorage.setItem("zeldaOut", true);
    window.Kustomer.logout();
    authService.logout();
  };

  getTimeRemaining = e => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  startTimer = e => {
    const { total, hours, minutes, seconds } = this.getTimeRemaining(e);

    const timeToDisplayCounter = parseInt(
      this.props.authUser.profile.timeToDisplayCounter,
      10
    );

    if (
      (minutes === timeToDisplayCounter && seconds === 0) ||
      (minutes <= timeToDisplayCounter && !this.state.showCounter)
    ) {
      this.setState({ showCounter: true });
    }

    if (minutes === 0 && seconds === 59) {
      this.setState({ startCountdown: true });
      this.setState({ showModal: true });
    }

    if (hours === 0 && minutes === 0 && seconds === 0) this.logout();

    if (total >= 0) {
      this.setState({
        timer: `${hours > 9 ? hours : `0${hours}`}:${
          minutes > 9 ? minutes : `0${minutes}`
        }:${seconds > 9 ? seconds : `0${seconds}`}`,
      });
    }
  };

  clearTimer = e => {
    this.setState({
      timer: `00:${parseInt(
        this.props.authUser.profile.allowedDownTime,
        10
      )}:00`,
    });
    if (this.Ref.current) clearInterval(this.Ref.current);
    const id = setInterval(() => {
      this.setState({
        zeldaOut: JSON.parse(localStorage.getItem("zeldaOut")),
        anyActiveTab: false,
      });
      this.startTimer(e);
      this.setState({
        anyActiveTab: JSON.parse(localStorage.getItem("anyActiveTab")),
      });
    }, 1000);
    this.Ref.current = id;
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  getDeadTime = () => {
    this.setState({ showCounter: false, startCountdown: false });
    const deadline = new Date();
    deadline.setSeconds(
      deadline.getSeconds() +
        parseInt(this.props.authUser.profile.allowedDownTime, 10) * 60
    );
    return deadline;
  };

  resetTime = () => {
    clearTimeout(time);
    this.setState({ anyActiveTab: true });
    this.clearTimer(this.getDeadTime());
  };

  confirm = () => {
    Modal.confirm({
      title: "Esperamos que hayas tenido un día productivo",
      content: "¿Deseas cerrar esta sesión?",
      okText: "Si, cerrar sesión",
      cancelText: "No aún",
      width: 450,
      onOk: this.logout,
    });
  };

  render() {

    const {deliveriesWrongSize} = this.state;
    const data = [
      {
        id: "000000001",
        title: `Tienes ${deliveriesWrongSize} órdenes con errores por validar.`,
        type: "notification",
      },
    ];
    function getNoticeData(notices) {
      if (notices.length === 0) {
        return {};
      }
      const newNotices = notices.map(notice => {
        const newNotice = { ...notice };
        if (newNotice.id) {
          newNotice.key = newNotice.id;
        }
        if (newNotice.extra && newNotice.status) {
          const color = {
            todo: "",
            processing: "blue",
            urgent: "red",
            doing: "gold",
          }[newNotice.status];
          newNotice.extra = (
            <Tag color={color} style={{ marginRight: 0 }}>
              {newNotice.extra}
            </Tag>
          );
        }
        return newNotice;
      });
      // eslint-disable-next-line no-shadow
      return newNotices.reduce((pre, data) => {
        if (!pre[data.type]) {
          // eslint-disable-next-line no-param-reassign
          pre[data.type] = [];
        }
        pre[data.type].push(data);
        return pre;
      }, {});
    }
    const noticeData = getNoticeData(data);

    const { showSideBar } = this.props;
    const menu = (
      <Menu>
        <Menu.Item key="0" disabled>
          <span>{this.props.authUser.profile.email}</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout" onClick={this.confirm}>
          Cerrar sesión
        </Menu.Item>
      </Menu>
    );

    return (
      <CustomHeader showSideBar={showSideBar} style={{ width: "100%" }}>
        {this.state.showCounter && (
          <Chronometer startCountdown={this.state.startCountdown}>
            <Icon type="clock-circle" />
            <b>{this.state.timer}</b>
          </Chronometer>
        )}

        <CustomContainer showSideBar={showSideBar}>
          <div
            style={{
              cursor: "pointer",
              padding: "0 20px",
              display: "inline-block",
              fontSize: "35px",
            }}
          >
            {deliveriesWrongSize>0 ?
              (
              <Link to={`${router.previewcsv}?id=2`}>
                <NoticeIcon
                  className="notice-icon"
                  count={
                    deliveriesWrongSize>0 || this.state.bell===true ? 1:""
                  }
                >
                  <NoticeIcon.Tab
                    list={
                      deliveriesWrongSize>0 || this.state.bell===true? noticeData.notification : ""}
                  />
                </NoticeIcon>
              </Link>

              ):(
              <NoticeIcon
                className="notice-icon"
                count={
                  deliveriesWrongSize>0 || this.state.bell===true ? 1:""
                }
              >
                <NoticeIcon.Tab
                  list={
                    deliveriesWrongSize>0 || this.state.bell===true? noticeData.notification : ""}
                />
              </NoticeIcon>
              )
            }
          </div>
          <div style={{ marginLeft: "20px" }}>
            <Avatar
              style={{ backgroundColor: "#096DD9" }}
              icon="user"
              size={50}
            />
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement="bottomRight"
            >
              <Button
                style={{
                  border: "none",
                  color: "#262626",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginLeft: "5px",
                  lineHeight: "20px",
                }}
                onClick={e => e.preventDefault()}
                className="ant-dropdown-link"
              >
                {this.props.authUser.profile.name} <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
          <ConfirmSessionClosure
            isModalVisible={this.state.showModal}
            logout={this.logout}
            hideModal={this.hideModal}
          />
        </CustomContainer>
      </CustomHeader>
    );
  }
}

export default compose(withGlobalContext)(HeaderComponent);
